<template>
  <v-card id="account-setting-card">
  <div id="Music" cols="12" sm="12" style="padding:30px">
    <h1>SnapShot</h1>
    <v-switch v-model="switch1" inset :label="`Want to Snapshot ?`"></v-switch>

    <v-form v-model="valid" ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <center style="font-size: 40px;">
              <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
              <br />
            </center>
          </v-col>
        </v-row>

        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          :right="x === 'right'"
          :top="y === 'top'"
          :color="color"
        >
          {{ text }}
          <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
      </v-container>
    </v-form>
    <br />
    <v-container>
      <v-overlay :value="overlay">
        <v-btn icon @click="overlay = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <img :src="myscr" alt="SpookApp" @click="close()" />

        <v-btn icon @click="overlay = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-overlay>
      <v-row cols="12" md="12">
        <v-col v-for="(item,key) in items" :key="key">
          <v-card :color="item.color">
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-avatar class="ma-3" size="125" tile @click="big(item.user_href)">
                <v-img :src="item.user_href"></v-img>
              </v-avatar>
              <v-col>
                <v-btn color="error" @click="big(item.user_href)">
                  <v-icon>mdi-arrow-expand</v-icon>Image
                </v-btn>
                <br />
                <br />
                <!--  <v-btn color="success" @click="big(item.user_href)">Download Image</v-btn> -->
                <v-btn color="success" :href="item.user_href">
                  <v-icon>mdi-arrow-down-bold-circle</v-icon>Image
                </v-btn>
              </v-col>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
// import { appConfig, userStorageRef } from "../../firebaseConfig";
export default {
  name: "Snapshot",
  data() {
    return {
      overlay: false,
      myscr: "",
      switch1: false,
      length: 5,
      valid: false,
      search: "",
      musicUrl: "",
      musicRules: [
        v => !!v || "Music URL is required",
        v => /\.(?:wav|mp3)$/i.test(v) || "Music must be valid"
      ],
      selected: null,
      notificationValues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      myitems: [],
      latitude: "",
      longitude: "",
      obj: {
        appVibration: {
          seconds: "",
          vibration: ""
        },
        battryPercentage: "",
        isCharging: "",
        bluetooth: "",
        brightness: "",
        flashlight: "",
        screenshot:"",
        mobileData: "",
        mobileDataType: "",
        musicUrl: {
          music: "",
          url: ""
        },
        sound: ""
      },
      color: "",
      mode: "",
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: "top",
      items: {}
    };
  },
  mounted: function() {
    // this.getObj();
    // this.getData();
  },

   methods: {
  //   close() {
  //     this.overlay = !this.overlay;
  //   },
  //   big(image) {
  //     this.overlay = !this.overlay;
  //     this.myscr = image;
  //   },
  //   async myD() {
  //     if (this.selected == null) {
  //       console.log(this.selected);
  //     } else if (this.selected == "Warriyo") {
  //       console.log(this.selected);
  //     } else if (this.selected == "LostSky") {
  //       console.log(this.selected);
  //     } else if (this.selected == "Jasico") {
  //       console.log(this.selected);
  //     } else if (this.selected == "Electro") {
  //       console.log(this.selected);
  //     }
  //   },
  //   getObj() {
  //     return appConfig
  //       .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
  //       .child("868068040704064")
  //       .on("value", snapshot => {
  //         this.obj = snapshot.val();
  //         console.log(this.obj);
  //       });
  //   },
  //   getData() {
  //     userStorageRef.on("value", snapshot => {
  //       console.log(snapshot.key);
  //       this.items = snapshot.val();
  //       console.log(snapshot.val());
  //     });
  //   },
  //   saveBrightness() {
  //     this.obj.screenshot = this.switch1;
  //     appConfig
  //       .child("78KOHWXhC0UTR3fxqkqsoJ7j1Ew1")
  //       .child("868068040704064")
  //       .set(this.obj);
  //     this.text = "successfully Changes";
  //     this.snackbar = true;
  //     this.color = "success";
  //   }
   }
};
</script>